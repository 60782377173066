import React from "react";

const HealthCheck = () => {
  return (
    <div className="flex-container text-center">
        <h3 style={{"text-align": "center"}}>"I'm Alive!"</h3>
    </div>
  );
};

export default HealthCheck;
